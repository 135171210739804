import React from 'react';
// @ts-ignore
import AdSense from 'react-adsense';

export const PageFooterAd = () => {
  return (
    <AdSense.Google
      client="ca-pub-1728240300661821"
      slot="6516285116"
      style={{ display: 'block' }}
      layout="in-article"
      format="auto"
      responsive="true"
    />
  );
};
