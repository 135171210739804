import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import VotingPage from '@pages/VotingPage';
// pages
// @ts-ignore
const HomePage = React.lazy(() => import('../pages/HomePage'));
// @ts-ignore
const ParticipatePage = React.lazy(() => import('../pages/ParticipatePage'));
// @ts-ignore
const AboutPage = React.lazy(() => import('../pages/AboutPage'));
// @ts-ignore
const ContactUsPage = React.lazy(() => import('../pages/ContactUsPage'));
// @ts-ignore
const PrizesPage = React.lazy(() => import('../pages/PrizesPage'));
// @ts-ignore
const PrivacyPolicyPage = React.lazy(() => import('../pages/PrivacyPolicyPage'));
// @ts-ignore
const RulesPage = React.lazy(() => import('../pages/RulesPage/RulesPage'));
// @ts-ignore
const TermsPage = React.lazy(() => import('../pages/TermsPage'));
// @ts-ignore
const FAQPage = React.lazy(() => import('../pages/FAQPage'));
// @ts-ignore
const HowItWorksPage = React.lazy(() => import('../pages/HowItWorksPage'));
// @ts-ignore
const LeaderboardPage = React.lazy(() => import('../pages/LeaderboardPage'));

const AppRouter = () => {
  return (
    <Routes>
      <Route path={AppRoutes.vote} element={<VotingPage />} />
      <Route path={AppRoutes.leaderboard} element={<LeaderboardPage />} />
      <Route path={AppRoutes.participate} element={<ParticipatePage />} />
      <Route path={AppRoutes.prizes} element={<PrizesPage />} />
      <Route path={AppRoutes.about} element={<AboutPage />} />
      <Route path={AppRoutes.privacyPolicy} element={<PrivacyPolicyPage />} />
      <Route path={AppRoutes.rules} element={<RulesPage />} />
      <Route path={AppRoutes.faq} element={<FAQPage />} />
      <Route path={AppRoutes.howItWorks} element={<HowItWorksPage />} />
      <Route path={AppRoutes.termsAndConditions} element={<TermsPage />} />
      <Route path={AppRoutes.contactus} element={<ContactUsPage />} />
      <Route path={AppRoutes.home} element={<HomePage />} />
      <Route path={'*'} element={<HomePage />} />
    </Routes>
  );
};

export default AppRouter;
