import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import Forward30RoundedIcon from '@mui/icons-material/Forward30Rounded';

const VotingIntervalCard = ({ name }: { name: string }) => {
  const body = VotingConsts.votingInterval.body.replace('<name>', name);
  return (
    <TitleAndBodySectionCard
      body={body}
      title={VotingConsts.votingInterval.title}
      id="vote-interval"
      Icon={Forward30RoundedIcon}
    />
  );
};

export default VotingIntervalCard;
