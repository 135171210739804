import React from 'react';

import { getVotingMessage } from '@utils/viewHelpers';
import { Clubs } from 'src/types';
import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';

const ParentsMessageCard = ({ voteFor, contest }: { voteFor: string; contest: Clubs }) => {
  const votingMsg = getVotingMessage(contest, voteFor);
  return (
    <TitleAndBodySectionCard
      body={votingMsg.body}
      title={votingMsg.title}
      id="parents-msg"
      Icon={MessageRoundedIcon}
    />
  );
};

export default ParentsMessageCard;
