export interface IPrize {
  rank: string;
  desc: string;
}

export enum ShareItWith {
  whatsApp,
  facebook,
  instagram,
  whatsAppStaus
}

export enum Clubs {
  cuteBaby = 'cute-baby',
  momDaughter = 'mom-daughter-twinning',
  fatherSon = 'father-son-twinning',
  rangoli = 'rangoli'
}
