import React, { useEffect } from 'react';

import { PaperWrapper } from '@components/common/PaperWrapper';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { VOTING_CARDS_PADDING } from './Voting/styleConfigs';

interface IProps {
  title: string;
  body: string | React.ReactElement[];
  Icon: any;
  // for setting id of for the body element
  id: string;
  subTitle?: React.ReactElement;
  footer?: React.ReactElement;
}

export const SectionBody = ({ bodyId }: { bodyId: string }) => {
  return (
    <Typography variant="body1" id={bodyId} marginTop={1}>
      {''}
    </Typography>
  );
};

const TitleAndBodySectionCard = (props: IProps) => {
  const theme = useTheme();

  const { title, body, Icon, id, subTitle = null, footer = null } = props;
  const bodyId = `card-body-${id}`;
  // @ts-ignore
  const primaryColor = theme.palette.primary[600];
  useEffect(() => {
    if (typeof body === 'string') {
      const bodyElement = document.getElementById(bodyId);
      if (bodyElement) bodyElement.innerHTML = body;
    }
  }, []);

  return (
    <PaperWrapper
      sx={{
        p: VOTING_CARDS_PADDING
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <Icon color={'primary'} fontSize="medium" />
        <Typography
          color={primaryColor}
          variant="h5"
          fontWeight={600}
          marginLeft={1}
          textTransform={'capitalize'}>
          {title}
        </Typography>
      </Box>
      {subTitle}
      {typeof body === 'string' && <SectionBody bodyId={bodyId} />}
      {footer}
    </PaperWrapper>
  );
};

export default TitleAndBodySectionCard;
