import React from 'react';
// @ts-ignore
import AdSense from 'react-adsense';

export const PageVerticalAd = () => {
  return (
    <AdSense.Google
      client="ca-pub-1728240300661821"
      slot="6902425785"
      style={{ display: 'block' }}
      format="autorelaxed"
    />
  );
};
