import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { drawerWidth, navItems } from '@consts/appConfigs';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { navigateToMenuPage } from '@navigation/NavHelpers';

interface IProps {
  window?: () => Window;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

export const AppDrawer = (props: IProps) => {
  const { window, handleDrawerToggle, mobileOpen } = props;
  const navigate = useNavigate();
  const container = window !== undefined ? () => window().document.body : undefined;

  const navigateToPage = (e: any,key: string) => {
    handleDrawerToggle();
    navigateToMenuPage(navigate, key);
  }

  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}>
        <Box sx={{ textAlign: 'center' }}>
          <img
            src={'logo.svg'}
            alt="Girl in a jacket"
            width="42"
            height="42"
          />
          <Divider />
          <List>
            {navItems.map((item) => (
              <ListItem key={item} disablePadding onClick={(e) => navigateToPage(e,item)}>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
