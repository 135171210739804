import React, { useCallback, useEffect, useState } from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const VotingLink = ({ votingUrl }: { votingUrl: string }) => {
  const [copied, setCopied] = useState<null | boolean>(null);

  useEffect(() => {
    let time: any = null;
    if (copied !== null) {
      time = setTimeout(() => {
        setCopied(null);
      }, 3000);
    }
    return () => {
      time && clearTimeout(time);
    };
  }, [copied]);

  const copyToClipBoard = () => {
    setCopied(null);
    navigator.clipboard
      .writeText(votingUrl)
      .then(() => {
        setCopied(true);
      })
      .catch((e) => {
        setCopied(false);
      });
  };

  const toast = () => {
    return copied ? (
      <Alert
        sx={{
          marginTop: 1
        }}
        variant="filled"
        severity="success">
        {'Voting link copied... Please share it with your friends and family.'}
      </Alert>
    ) : (
      <Alert variant="filled" severity="warning">
        {'Failed to copy, try again!'}
      </Alert>
    );
  };

  return (
    <>
      <Stack direction="row" spacing={0} marginTop={1}>
        <Button
          variant="outlined"
          sx={{
            width: '100%',
            borderRadius: 0,
            textTransform: 'none',
            fontWeight: 'bold'
          }}
          onClick={copyToClipBoard}
          size={'medium'}>
          {votingUrl}
        </Button>
        <Button
          size={'medium'}
          sx={{
            borderRadius: 0
          }}
          onClick={copyToClipBoard}
          variant="contained"
          color="success">
          Copy
        </Button>
      </Stack>
      {copied !== null && toast()}
    </>
  );
};
const VotingLinkCard = ({ name, votingUrl }: { name: string; votingUrl: string }) => {
  const bodyTxt = VotingConsts.votingLink.body.replace('<name>', name);
  return (
    <TitleAndBodySectionCard
      body={bodyTxt}
      title={VotingConsts.votingLink.title}
      id="voting-link-msg"
      Icon={LinkIcon}
      footer={<VotingLink votingUrl={votingUrl} />}
    />
  );
};

export default VotingLinkCard;
