import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@navigation/AppRoutes';
import BoxWrapper from '@components/common/BoxWrapper';

const ViewRulesBtn = () => {
  const navigate = useNavigate();

  const openRulesPage = () => {
    navigate(AppRoutes.rules);
  };

  return (
    <BoxWrapper justifyContent="center">
      <Button variant="contained" onClick={openRulesPage}>
        {'SHOW CONTEST RULES'}
      </Button>
    </BoxWrapper>
  );
};
const VoteCarefullyCard = () => {
  return (
    <TitleAndBodySectionCard
      body={VotingConsts.voteCarefully.body}
      title={VotingConsts.voteCarefully.title}
      id="votecareful-msg"
      Icon={WarningRoundedIcon}
      footer={<ViewRulesBtn />}
    />
  );
};

export default VoteCarefullyCard;
