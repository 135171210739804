import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function LazyPageSkeleton() {
  return (
    <Stack spacing={4}>
      <Skeleton variant="rounded" height={40} />
      <Skeleton variant="rounded" height={60} />
      <Skeleton variant="rounded" height={80} />
      <Skeleton variant="rounded" height={100} />
    </Stack>
  );
}