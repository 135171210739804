import React from 'react';

import HeadingWithBackGround from '@components/HeadingWithBackGround';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import Button from '@mui/material/Button';
import AppConsts from '@consts/appStrings.json';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import RedeemIcon from '@mui/icons-material/Redeem';
import CalculateIcon from '@mui/icons-material/Calculate';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Grid from '@mui/material/Grid';

const ClickToVote = ({ totalVotes }: { totalVotes: number }) => {
  const callVoteApi = () => {
    // TODO increment vote and call
  };

  return (
    <Button
      size={'large'}
      sx={{
        width: '100%',
        marginTop: 1
      }}
      onClick={callVoteApi}
      variant="contained">
      {AppConsts.actions.clickToVote}
    </Button>
  );
};
enum IconKeys {
  vote,
  bonus,
  total,
  rank
}
const Vote = ({ totalVotes, name }: { totalVotes: number; name: string }) => {
  const theme = useTheme();

  const getIcon = (key: IconKeys) => {
    let tileIcon = null;
    switch (key) {
      case IconKeys.vote:
        tileIcon = <VolunteerActivismIcon color={'warning'} />;
        break;
      case IconKeys.bonus:
        tileIcon = <RedeemIcon color={'warning'} />;
        break;
      case IconKeys.total:
        tileIcon = <CalculateIcon color={'warning'} />;
        break;
      case IconKeys.rank:
        tileIcon = <MilitaryTechIcon color={'warning'} />;
        break;
    }
    return tileIcon;
  };
  const paper = (count: number, icon: IconKeys, text: string) => {
    return (
      <Grid item md={3} xs={6}>
        <Box sx={{ border: 2, borderColor: '#546E7A', padding: 1, borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom textAlign={'center'} fontWeight={900}>
            {count}
          </Typography>
          <Box display={'flex'} flexDirection="row" alignItems={'center'} justifyContent={'center'}>
            {getIcon(icon)}
            <Typography variant="h6" textAlign={'center'} fontWeight={500} marginLeft={0.5}>
              {text}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <Typography variant="h5" fontWeight={500} textAlign={'center'} gutterBottom>
          {'Vote for'}
        </Typography>
        <Typography
          variant="h5"
          textAlign={'center'}
          fontWeight={700}
          marginLeft={0.5}
          gutterBottom
          textTransform={'uppercase'}>
          {name}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {paper(100, IconKeys.vote, 'VOTES')}
        {paper(0, IconKeys.bonus, 'BONUS')}
        {paper(1000, IconKeys.total, 'TOTAL')}
        {paper(11, IconKeys.rank, 'RANK')}
      </Grid>
      <ClickToVote totalVotes={totalVotes} />
    </Box>
  );
};

const VoteCard = ({ totalVotes, name }: { totalVotes: number; name: string }) => {
  const theme = useTheme();
  // @ts-ignore
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <HeadingWithBackGround
      heading={<Vote totalVotes={totalVotes} name={name} />}
      fontWeight={400}
      textTransform="none"
      headerBgColor={isDarkMode ? '' : '#E0E0E0'}
    />
  );
};

export default VoteCard;
