import { ENDING_DAY_OF_CONTEST_VOTING } from '@consts/appConfigs';

export const getCurrentMonth = (date?: any) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const today = date ?? new Date();
  return month[today.getMonth()];
};

export const getCurrentMonthYear = () => {
  const date = new Date();
  const month = getCurrentMonth(date);
  return `${month} ${date.getFullYear()}`;
};

export const getOngoingContestMonth = () => {
  const todayDate = new Date();
  // countDownDate is 10th of next month
  let countDownMonth = todayDate.getMonth();
  let countDownYear = todayDate.getFullYear();
  // if current date less than the voting end date,
  // then consider the prev month.
  if (todayDate.getDate() < ENDING_DAY_OF_CONTEST_VOTING) {
    if (countDownMonth === 0) {
      // to handle Jan month, Dec should be the month and -1 for year
      countDownYear = countDownYear - 1;
      // 11 is for Dec
      countDownMonth = 11; // so Dec-2022
    } else {
      // for prev month
      countDownMonth--;
    }
  }
  const onGoingMonth = new Date(countDownYear, countDownMonth);
  return {
    month: getCurrentMonth(onGoingMonth),
    year: onGoingMonth.getFullYear()
  };
};

export const getContestRegEndDate = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const month = getCurrentMonth(today);
  return `${month} ${lastDayOfMonth.getDate()}, ${today.getFullYear()} (11:59:59PM, IST)`;
};

const getCountDownMetrics = () => {
  const todayDate = new Date();
  const now = todayDate.getTime();

  // countDownDate is 10th of next month
  let countDownMonth = todayDate.getMonth();
  let countDownYear = todayDate.getFullYear();
  // if current date has passed the end date of last month voting date,
  // then consider the next month end date.
  if (todayDate.getDate() > ENDING_DAY_OF_CONTEST_VOTING) {
    if (countDownMonth === 11) {
      // to handle Dec month, Jan should be next month and +1 for year
      countDownYear = countDownYear + 1;
      // 0 is for Jan
      countDownMonth = 0;
    } else {
      // for next month add 1
      countDownMonth++;
    }
  }
  return {
    countDownMonth,
    countDownYear,
    now
  };
};

export const getContestVotingEndDate = () => {
  const { countDownYear, countDownMonth } = getCountDownMetrics();

  const lastDayOfVoting = new Date(countDownYear, countDownMonth, ENDING_DAY_OF_CONTEST_VOTING);
  const month = getCurrentMonth(lastDayOfVoting);
  return `${month} ${lastDayOfVoting.getDate()}, ${lastDayOfVoting.getFullYear()} (11:59:59PM, IST)`;
};

export const getLeftOutContestTime = () => {
  const { countDownYear, countDownMonth, now } = getCountDownMetrics();
  const countDownDate = new Date(
    countDownYear,
    countDownMonth,
    ENDING_DAY_OF_CONTEST_VOTING,
    23,
    59,
    59
  );
  const timeleft = countDownDate.getTime() - now;

  return {
    Days: Math.floor(timeleft / (1000 * 60 * 60 * 24)),
    Hrs: Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    Mins: Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)),
    Secs: Math.floor((timeleft % (1000 * 60)) / 1000)
  };
};
