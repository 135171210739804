import React from 'react';

import HeadingWithBackGround from '@components/HeadingWithBackGround';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import { currentBonus, currentVotingLevel } from '@utils/viewHelpers';

const UnlockedLevel = ({ totalVotes, name }: { totalVotes: number; name: string }) => {
  const theme = useTheme();

  const currentLevel = currentVotingLevel(totalVotes);

  // @ts-ignore
  const primaryPalette = theme.palette.primary;

  return (
    <Box>
      <Typography
        textTransform={'capitalize'}
        color={primaryPalette.contrastText}
        variant="h5"
        gutterBottom
        textAlign={'center'}>
        {`${name}'s WOW-Level`}
      </Typography>
      <Typography
        textTransform={'capitalize'}
        color={primaryPalette.contrastText}
        variant="h4"
        gutterBottom
        fontWeight={600}
        textAlign={'center'}>
        {`💎 Level ${currentLevel}`}
      </Typography>
      <Typography
        textTransform={'capitalize'}
        color={primaryPalette.contrastText}
        variant="h5"
        gutterBottom
        fontWeight={600}
        textAlign={'center'}>
        {`💖 Bonus Votes ${currentBonus(currentLevel)}`}
      </Typography>
      <Typography
        gutterBottom
        color={primaryPalette.contrastText}
        variant="h5"
        textAlign={'center'}>
        {'To unlock next level and get more bonus, share voting link & get more votes.'}
      </Typography>
    </Box>
  );
};

const VotingLevelCard = ({ totalVotes, name }: { totalVotes: number; name: string }) => {
  const theme = useTheme();
  // @ts-ignore
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <>
      {totalVotes === 0 ? (
        <HeadingWithBackGround
          heading={'Get your first vote to unblock WOW-Level.'}
          fontWeight={400}
          textTransform="none"
        />
      ) : (
        <HeadingWithBackGround
          heading={<UnlockedLevel totalVotes={totalVotes} name={name} />}
          fontWeight={400}
          textTransform="none"
          headerBgColor={isDarkMode ? '' : '#0097A7'}
        />
      )}
    </>
  );
};

export default VotingLevelCard;
