import { AppRoutes } from '@navigation/AppRoutes';
import { isDev } from '@utils/envHelpers';

export const navItems = ['Home', 'Participate', 'How To Play', 'Prizes', 'About', 'Rules', 'FAQ'];

export const drawerWidth = 240;

export const URLs = Object.freeze({
  copyRight: AppRoutes.copyRight,
  privacyPolicy: AppRoutes.privacyPolicy,
  termsAndConditions: AppRoutes.termsAndConditions,
  contactUs: AppRoutes.contactus
});

export const MINIMUM_VOTES = 499;

export const FB_LINK = 'https://www.facebook.com/profile.php?id=100090048057185';

export const INSTA_LINK = 'https://www.instagram.com/thecontestsclub/';

export const OUR_EMAIL_ID = 'thecontestsclub+website@gmail.com';

export const UPLOAD_IMAGE_TYPES = '.jpg,.jpeg,.png';

export const TOTAL_WINNERS = {
  CUTE_BABY: 21
};

// keeping end date for voting on 10th of the next month
export const ENDING_DAY_OF_CONTEST_VOTING = 10;
// keeping winners announcement on 15th of the next month
export const WINNERS_ANNOUNCEMENT_DAY_OF_CONTEST = 15;

export const VOTING_LEVEL_BASE = 100;

export const BONUS_MULTIPLIER = 10;
