import React, { useEffect, useState } from 'react';
import { Clubs } from 'src/types';
import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import VotingConsts from '@consts/voting.json';
import { timeLeftForVotingMsg } from '@utils/viewHelpers';
import { getLeftOutContestTime } from '@utils/dateHelpers';
import Typography from '@mui/material/Typography';

const Timer = () => {
  const [timeLeft, setTimer] = useState<{ Days: number; Hrs: number; Mins: number; Secs: number }>(
    getLeftOutContestTime()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(getLeftOutContestTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const timerComponents: string[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    // @ts-ignore
    if (timeLeft[interval] === 0) {
      return;
    }
    // @ts-ignore
    timerComponents.push(`<b>${timeLeft[interval]}</b> ${interval} `);
  });

  return (
    <Typography variant="h5" py={1}>
      {timerComponents.length
        ? Object.keys(timeLeft).map((interval, index) => {
            // @ts-ignore
            if (timeLeft[interval] === 0) {
              return null;
            }
            return (
              <>
                <b key={interval}>
                  {
                    // @ts-ignore
                    timeLeft[interval]
                  }
                </b>{' '}
                {interval}
                {index !== Object.keys(timeLeft).length - 1 && ' : '}
              </>
            );
          })
        : "Time's up!"}
    </Typography>
  );
};

const VotingTimeLeftCard = ({ contest }: { contest: Clubs }) => {
  const bodyMsg = timeLeftForVotingMsg(contest);
  return (
    <TitleAndBodySectionCard
      body={bodyMsg}
      subTitle={<Timer />}
      title={VotingConsts.votingTimeLeft.title}
      id="vote-time-left"
      Icon={HourglassTopTwoToneIcon}
    />
  );
};

export default VotingTimeLeftCard;
