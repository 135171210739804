import React from 'react';
import Box from '@mui/material/Box';

interface IProps {
  children: React.ReactElement | React.ReactElement[];
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'left';
  flexDirection?: 'column' | 'row';
  mt?: number;
}

const BoxWrapper = (props: IProps) => {
  const { children, flexDirection, mt, ...rest } = props;
  return (
    <Box
      mt={mt ?? 2}
      maxWidth={'xl'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: flexDirection ?? 'column',
        ...rest
      }}>
      {children}
    </Box>
  );
};

export default BoxWrapper;
