import React from 'react';
import Typography from '@mui/material/Typography';
import Consts from '@consts/index';
import { PaperWrapper } from '@components/common/PaperWrapper';
import ParticipateBtn from '@components/ParticipateBtn';
import { getCurrentMonth } from '@utils/dateHelpers';

const RegistrationOpenContainer = () => {
  return (
    <PaperWrapper
      sx={{
        padding: 3
      }}>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        {Consts.Strings.RegOpenSection.title}
      </Typography>
      <Typography
        variant="h5"
        textAlign={'center'}
        gutterBottom
        sx={{
          textDecoration: 'underline'
        }}>
        {Consts.Strings.RegOpenSection.subTitle.replace('<month>', getCurrentMonth())}
      </Typography>
      <Typography variant="h6" textAlign={'center'} gutterBottom>
        {Consts.Strings.RegOpenSection.body}
      </Typography>
      <ParticipateBtn />
    </PaperWrapper>
  );
};

export default RegistrationOpenContainer;
