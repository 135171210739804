import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';

const BonusCard = () => {
  return (
    <TitleAndBodySectionCard
      body={VotingConsts.bonusData.body}
      title={VotingConsts.bonusData.title}
      id="bonus-body"
      Icon={RedeemTwoToneIcon}
    />
  );
};

export default BonusCard;
