import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';

const ThankYouCard = () => {
  return (
    <TitleAndBodySectionCard
      body={VotingConsts.thankYou.body}
      title={VotingConsts.thankYou.title}
      id="thank-you-body"
      Icon={VolunteerActivismRoundedIcon}
    />
  );
};

export default ThankYouCard;
