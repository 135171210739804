import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import ShareIcon from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Clubs, ShareItWith } from '../../types';
import { formatShareMessage } from '@utils/shareHelpers';

const ShareButton = styled(Button)<any>((props) => {
  return {
    backgroundColor: props.buttonColor,
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: props.buttonColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: props.buttonColor
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  };
});

const ShareOptions = ({
  votingUrl,
  name,
  contest
}: {
  votingUrl: string;
  name: string;
  contest: Clubs;
}) => {
  const whatsAppMessage = formatShareMessage(ShareItWith.whatsApp, name, votingUrl, contest);
  return (
    <Stack direction="column" spacing={1} marginTop={1}>
      <ShareButton
        variant="contained"
        buttonColor={'#25D366'}
        size={'large'}
        href={`whatsapp://send?text=${whatsAppMessage}`}
        startIcon={<WhatsAppIcon />}>
        {'WhatsApp'}
      </ShareButton>
      <ShareButton
        variant="contained"
        buttonColor={'#1877F2'}
        sx={{
          width: '100%',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          backgroundColor: '#1877F2'
        }}
        target="_blank"
        href={`http://www.facebook.com/sharer/sharer.php?u=${votingUrl}`}
        size={'large'}
        startIcon={<FacebookIcon />}>
        {'Facebook'}
      </ShareButton>
      <ShareButton
        variant="contained"
        buttonColor={'#25D366'}
        size={'large'}
        href={`whatsapp://send?text=${whatsAppMessage}`}
        startIcon={<WhatsAppIcon />}>
        {'WhatsApp Status'}
      </ShareButton>
    </Stack>
  );
};
const ShareCard = ({
  name,
  votingUrl,
  contest
}: {
  name: string;
  votingUrl: string;
  contest: Clubs;
}) => {
  const bodyTxt = VotingConsts.share.body.replace('<name>', name);
  return (
    <TitleAndBodySectionCard
      body={bodyTxt}
      title={VotingConsts.share.title}
      id="share-card"
      Icon={ShareIcon}
      footer={<ShareOptions votingUrl={votingUrl} name={name} contest={contest} />}
    />
  );
};

export default ShareCard;
