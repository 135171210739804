import React from 'react';
import Typography from '@mui/material/Typography';
import Consts from '@consts/index';
import BoxWrapper from '@components/common/BoxWrapper';
import Grid from '@mui/material/Grid';
import ParticipateBtn from '@components/ParticipateBtn';
import MonthlyPrizeContainer from './MonthlyPrizeContainer';

const RankAndBriefContainer = () => {
  return (
    <BoxWrapper justifyContent="center" mt={0}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <MonthlyPrizeContainer />
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography
            variant="h5"
            textAlign={'left'}
            marginLeft={0.5}
            gutterBottom
            textTransform={'capitalize'}>
            {Consts.Strings.prizeAndBrief.briefTitle}
          </Typography>
          <Typography gutterBottom textAlign={'left'} marginLeft={0.5} lineHeight={2.5} paragraph>
            {Consts.Strings.prizeAndBrief.briefBody}
          </Typography>
          <ParticipateBtn />
        </Grid>
      </Grid>
    </BoxWrapper>
  );
};

export default RankAndBriefContainer;
