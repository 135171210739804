import ChildCareIcon from '@mui/icons-material/ChildCare';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaletteIcon from '@mui/icons-material/Palette';
import { Clubs } from '../types/index';
import VotingConsts from '@consts/voting.json';
import { getContestVotingEndDate, getOngoingContestMonth } from './dateHelpers';
import { BONUS_MULTIPLIER, VOTING_LEVEL_BASE } from '@consts/appConfigs';
import Participate from '@consts/participate.json';

export const getClubsIcon = (key: string) => {
  switch (key) {
    case Clubs.cuteBaby:
      return ChildCareIcon;
    case Clubs.fatherSon:
      return PeopleAltIcon;
    case Clubs.momDaughter:
      return PeopleAltIcon;
    case Clubs.rangoli:
      return PaletteIcon;
    default:
      return null;
  }
};

export const getVoteForMessage = (contestClub: Clubs, name: string) => {
  let msg = VotingConsts.voteForMsg;
  switch (contestClub) {
    case Clubs.cuteBaby:
      msg = `${msg}${VotingConsts.myCuteBaby}`;
      break;
    default:
      msg = `${msg}${VotingConsts.myCuteBaby}`;
  }
  return msg.replace('<baby_name>', name);
};

export const getVotingMessage = (contestClub: Clubs, name: string) => {
  const msg = {
    title: '',
    body: ''
  };
  switch (contestClub) {
    case Clubs.cuteBaby:
      msg.title = VotingConsts.parentsMsg.title;
      msg.body = VotingConsts.parentsMsg.body.replace('<baby_name>', name);
      break;
    default:
    // do nothing;
  }
  return msg;
};

export const timeLeftForVotingMsg = (contestClub: Clubs) => {
  let msg = VotingConsts.votingTimeLeft.body;
  switch (contestClub) {
    case Clubs.cuteBaby:
      msg = msg.replace('<contest_name>', 'Cute Baby Contest');
      break;
    default:
    // do nothing;
  }
  const onGoingMonth = getOngoingContestMonth();
  msg = msg
    .replace('<month>', `${onGoingMonth.month} ${onGoingMonth.year}`)
    .replace('<time>', getContestVotingEndDate());
  return msg;
};

/**
 * Consider level as 1 if total votes
 * less than VOTING_LEVEL_BASE.
 * @param totalVotes
 * @returns
 */
export const currentVotingLevel = (totalVotes: number) =>
  totalVotes <= VOTING_LEVEL_BASE ? 1 : Math.floor(totalVotes / VOTING_LEVEL_BASE);

/**
 *
 * @param currentLevel totalVotes / VOTING_LEVEL_BASE
 * @returns  l1 = +10, l2 = +20, l3 = +30
 *  hence Sn = n(n+1)/2 for natural numbers * 10
 *  as per our bonus calculation system
 */
export const currentBonus = (currentLevel: number) => {
  return ((currentLevel * (currentLevel + 1)) / 2) * BONUS_MULTIPLIER;
};

export const getContestName = (contestClub: Clubs) => {
  const contestNameMap = new Map<Clubs, string>();
  Participate.clubs.list.forEach((contest) => {
    contestNameMap.set(contest.key as Clubs, contest.display);
  });
  return contestNameMap.get(contestClub) ?? '';
};
