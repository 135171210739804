import React from 'react';

import HeadingWithBackGround from '@components/HeadingWithBackGround';
import Typography from '@mui/material/Typography';
import { getVoteForMessage } from '@utils/viewHelpers';
import { Clubs } from 'src/types';

const VoteFor = ({ voteFor, contest }: { voteFor: string; contest: Clubs }) => {
  const voteForMsg = getVoteForMessage(contest, voteFor);
  return (
    <>
      <HeadingWithBackGround heading={`Vote for ${voteFor}`} />
      <Typography
        textTransform={'capitalize'}
        fontWeight={600}
        variant="h4"
        textAlign={'center'}
        py={1}>
        {voteForMsg}
      </Typography>
    </>
  );
};

export default VoteFor;
