import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey, pink, blueGrey, deepOrange } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import React from 'react';
import { getLocalStorageVal, LOCAL_STORAGE_KEYS } from '@utils/localStorageHelpers';

const darkModePalette = {
  // palette values for dark mode
  primary: blueGrey,
  divider: blueGrey[700],
  background: {
    default: '#212121',
    paper: '#212121'
  },
  text: {
    primary: '#fff',
    secondary: grey[500]
  }
};

const lightModePalette = {
  primary: pink,
  secondary: deepOrange,
  divider: pink[200],
  text: {
    primary: grey[900],
    secondary: grey[800]
  }
};

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? lightModePalette : darkModePalette)
  }
});

export const preferedTheme = () => {
  const themFromStorage = getLocalStorageVal(LOCAL_STORAGE_KEYS.theme);
  if (themFromStorage && typeof themFromStorage === 'string') {
    // @ts-ignore
    return themFromStorage;
  }
  return 'light';
};

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const getTheme = (mode: PaletteMode) => {
  return responsiveFontSizes(createTheme(getDesignTokens(mode)));
};

export const isDarkTheme = (theme: any) => {
  return theme.palette.mode === 'dark';
};
