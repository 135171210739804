import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Consts from '@consts/index';
import BoxWrapper from '@components/common/BoxWrapper';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { FB_LINK, INSTA_LINK, URLs } from '@consts/appConfigs';
import { getPageFullUrl } from '@navigation/NavHelpers';

function Copyright() {
  return (
    <BoxWrapper justifyContent="center" flexDirection="row">
      <Typography variant="body2" color="text.secondary" textAlign={'center'}>
        {'Copyright © '}
        <Link target="_blank" color="inherit" href={URLs.copyRight}>
          {'The Contest Club'}
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved.'}
      </Typography>
    </BoxWrapper>
  );
}

function FooterLinks(props: { name: string; url: string }) {
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      mx={2}
      href={getPageFullUrl(props.url)}
      underline="hover"
      variant="body1"
      fontWeight={'500'}>
      {props.name}
    </Link>
  );
}

const FooterContainer = () => {
  const openSocialPages = (socialKey: string) => {
    if (socialKey === 'fb') {
      window.open(FB_LINK);
    } else if (socialKey === 'insta') {
      window.open(INSTA_LINK);
    }
  };
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
      }}>
      <Container maxWidth="sm">
        <Typography variant="h6" textAlign={'center'}>
          {Consts.Strings.footerSection.title}
        </Typography>
        <BoxWrapper justifyContent="center" flexDirection="row">
          <Button
            variant="outlined"
            onClick={() => openSocialPages('insta')}
            endIcon={<InstagramIcon fontSize="medium" />}
            size="medium">
            {Consts.Strings.footerSection.instagram}
          </Button>
          <Button
            sx={{
              marginLeft: 2
            }}
            variant="outlined"
            onClick={() => openSocialPages('fb')}
            endIcon={<FacebookIcon fontSize="medium" />}
            size="medium">
            {Consts.Strings.footerSection.facebook}
          </Button>
        </BoxWrapper>
        <Box
          my={2}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
          }}>
          <FooterLinks
            name={Consts.Strings.footerSection.termsAndConditions}
            url={URLs.termsAndConditions}
            key={'1'}
          />
          <FooterLinks
            name={Consts.Strings.footerSection.privacyPolicy}
            url={URLs.privacyPolicy}
            key={'3'}
          />
          <FooterLinks
            name={Consts.Strings.footerSection.contactUs}
            url={URLs.contactUs}
            key={'4'}
          />
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
};

export default FooterContainer;
