import { AppRoutes } from './AppRoutes';

export const navigateToMenuPage = (navigate: any, key: string) => {
  switch (key) {
    case 'Home':
      navigate(AppRoutes.home);
      break;
    case 'Participate':
      navigate(AppRoutes.participate);
      break;
    case 'Prizes':
      navigate(AppRoutes.prizes);
      break;
    case 'About':
      navigate(AppRoutes.about);
      break;
    case 'Contact Us':
      navigate(AppRoutes.contactus);
      break;
    case 'Rules':
      navigate(AppRoutes.rules);
      break;
    case 'FAQ':
      navigate(AppRoutes.faq);
      break;
    case 'How To Play':
      navigate(AppRoutes.howItWorks);
      break;
  }
};

export const getPageFullUrl = (pageSubUrl: string) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}${pageSubUrl}`;
};
