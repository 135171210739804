import React from 'react';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Consts from '@consts/index'

interface IState {
  hasError: boolean;
}
interface IProps {
  children: React.ReactElement;
}
export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log('getDerivedStateFromError', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh'
        }}>
          <EngineeringRoundedIcon sx={{ fontSize: 100 }} color='primary' />
          <Typography variant="h3" gutterBottom textAlign={"center"} color={"info"}>
            {Consts.Strings.common.somethingWrong}
          </Typography>
        </Box>)
    }

    return this.props.children;
  }
}
