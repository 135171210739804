import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IPrize } from 'src/types';
import Consts from '@consts/index';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1
  }
}));

export default function PrizeTable({ prizes }: { prizes: IPrize[] }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{Consts.Strings.common.rank}</StyledTableCell>
            <StyledTableCell>{Consts.Strings.common.winnerPrize}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prizes.map((prz) => (
            <StyledTableRow key={prz.rank}>
              <StyledTableCell component="th" scope="row">
                {prz.rank}
              </StyledTableCell>
              <StyledTableCell align="left">
                {prz.desc.replace('<min_votes>', `${Consts.Configs.MINIMUM_VOTES}`)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
