import React from 'react';

const ImageCard = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <img
      src={imageUrl}
      style={{
        width: '100%',
        objectFit: 'contain',
        borderRadius: 4,
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
      }}
    />
  );
};

export default ImageCard;
