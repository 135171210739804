import React from 'react';
// @ts-ignore
import AdSense from 'react-adsense';

export const PageIntermediateAd = () => {
  return (
    <AdSense.Google
      client="ca-pub-1728240300661821"
      slot="6889504410"
      style={{ display: 'block' }}
      format="fluid"
      layoutKey="-6s+ed+2g-1n-4q"
    />
  );
};
