import React, { ReactElement } from 'react';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { PaperWrapper } from './common/PaperWrapper';
import { VOTING_CARDS_PADDING } from './Voting/styleConfigs';

const HeadingWithBackGround = ({
  heading,
  fontWeight = 600,
  textTransform = 'capitalize',
  headerBgColor
}: {
  heading: string | ReactElement;
  fontWeight?: number;
  textTransform?: string;
  headerBgColor?: string;
}) => {
  const theme = useTheme();

  // @ts-ignore
  const primaryPalette = theme.palette.primary;

  return (
    <PaperWrapper
      sx={{
        p: VOTING_CARDS_PADDING,
        bgcolor: headerBgColor ?? primaryPalette.dark
      }}>
      {typeof heading === 'string' ? (
        <Typography
          // @ts-ignore
          textTransform={textTransform}
          color={primaryPalette.contrastText}
          fontWeight={fontWeight}
          variant="h4"
          textAlign={'center'}>
          {heading}
        </Typography>
      ) : (
        heading
      )}
    </PaperWrapper>
  );
};

export default HeadingWithBackGround;
