import React, { useEffect, Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from '@components/common/ErrorBoundary';

import AppRouter from './navigation/AppRouter';
import { ColorModeContext, getTheme, preferedTheme } from './theme';
import MainLayout from './layout/MainLayout';
import LazyPageSkeleton from '@components/LazyPageSkeleton';
import { LOCAL_STORAGE_KEYS, setLocalStorageVal } from '@utils/localStorageHelpers';

import './app.css';

function PageFallback() {
  return (
    <div style={{ margin: 16 }}>
      <LazyPageSkeleton />
    </div>
  );
}

function App() {
  // @ts-ignore
  const [mode, setMode] = React.useState<'light' | 'dark'>(preferedTheme());

  const theme = React.useMemo(() => getTheme(mode), [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );

  useEffect(() => {
    setLocalStorageVal(LOCAL_STORAGE_KEYS.theme, mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <Router>
            <Suspense fallback={<PageFallback />}>
              <MainLayout>
                <AppRouter />
              </MainLayout>
            </Suspense>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
