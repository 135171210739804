import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';

const FinalVoteCalCard = () => {
  return (
    <TitleAndBodySectionCard
      body={VotingConsts.finalVotes.body}
      title={VotingConsts.finalVotes.title}
      id="final-vote-body"
      Icon={CalculateRoundedIcon}
    />
  );
};

export default FinalVoteCalCard;
