import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useParams, useSearchParams } from 'react-router-dom';
import VoteFor from '@components/Voting/VoteFor';
import { Clubs } from '../types';
import ParentsMessageCard from '@components/Voting/ParentsMessageCard';
import { VOTING_SECTION_TOP_SPACE } from '@components/Voting/styleConfigs';
import ImageCard from '@components/Voting/ImageCard';
import VoteCarefullyCard from '@components/Voting/VoteCarefullyCard';
import VotingIntervalCard from '@components/Voting/VotingIntervalCard';
import VotingTimeLeftCard from '@components/Voting/VotingTimeLeftCard';
import BonusCard from '@components/Voting/BonusCard';
import FinalVoteCalCard from '@components/Voting/FinalVoteCalCard';
import ThankYouCard from '@components/Voting/ThankYouCard';
import VotingLinkCard from '@components/Voting/VotingLinkCard';
import VotingLevelCard from '@components/Voting/VotingLevelCard';
import ShowLBCard from '@components/Voting/ShowLBCard';
import VoteCard from '@components/Voting/VoteCard';
import ShareCard from '@components/Voting/ShareCard';
import { GoogleAds } from '@components/GoogleAds';

const VotingMainContainer = () => {
  const [urlSearchParams] = useSearchParams();
  const pathParams = useParams();
  console.log('VotingPage----', pathParams, urlSearchParams, urlSearchParams.get('contest'));

  const contestClub = (urlSearchParams.get('contest') as Clubs) ?? Clubs.cuteBaby;
  const name = 'Sanchita';
  const totalVotes = 201;
  const votingUrl = 'https://www.thisisvoring.url';

  useEffect(() => {
    // TODO get voting data
  }, []);

  return (
    <>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VoteFor voteFor={name} contest={contestClub} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <ParentsMessageCard voteFor={name} contest={contestClub} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <ImageCard
          imageUrl={'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg'}
        />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VoteCarefullyCard />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VotingIntervalCard name={name} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VotingTimeLeftCard contest={contestClub} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <BonusCard />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <FinalVoteCalCard />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VotingLevelCard totalVotes={totalVotes} name={name} />
      </Box>
      <GoogleAds.PageIntermediateAd />
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VoteCard totalVotes={totalVotes} name={name} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <ShareCard name={name} votingUrl={votingUrl} contest={contestClub} />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <VotingLinkCard name={name} votingUrl={votingUrl} />
      </Box>
      <GoogleAds.PageVerticalAd />
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <ShowLBCard />
      </Box>
      <Box mt={VOTING_SECTION_TOP_SPACE}>
        <ThankYouCard />
      </Box>
    </>
  );
};

export default VotingMainContainer;
