import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { navItems } from '@consts/appConfigs';
import Logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { navigateToMenuPage } from '@navigation/NavHelpers';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../theme';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Typography from '@mui/material/Typography';

interface IProps {
  handleDrawerToggle: () => void;
}

const AppTopBar = (props: IProps) => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const { handleDrawerToggle } = props;
  const navigate = useNavigate();

  const navigateToPage = (key: string) => {
    navigateToMenuPage(navigate, key);
  };

  return (
    <AppBar component="nav">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}>
          <MenuIcon />
        </IconButton>
        <img
          src={Logo}
          alt={'Logo'}
          loading="lazy"
          width="38"
          height="64"
          onClick={() => navigateToPage('Home')}
        />
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {navItems.map((item) => (
            <Button key={item} sx={{ color: '#fff' }} onClick={() => navigateToPage(item)}>
              {item}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 18
          }}>
          <Typography variant="subtitle2" display="block" fontWeight={600}>
            {'Light/Dark'}
          </Typography>
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppTopBar;
