import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { AppDrawer } from './AppDrawer';
import AppTopBar from './AppTopBar';
import Container from '@mui/material/Container';
import FooterContainer from '@containers/FooterContainer';
import RankAndBriefContainer from '@containers/RankAndBriefContainer';
import { GoogleAds } from '@components/GoogleAds';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactNode;
}

export default function MainLayout(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppTopBar handleDrawerToggle={handleDrawerToggle} />
      <AppDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      <Container component="main" sx={{ p: 3 }} style={{ width: '100%' }}>
        <Toolbar />
        {props.children}
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto'
        }}>
        <RankAndBriefContainer />
      </Box>
      {/* <GoogleAds.PageFooterAd currentPath={window.location.href} /> */}
      <FooterContainer />
    </Box>
  );
}
