export enum LOCAL_STORAGE_KEYS {
  theme = 'theme'
}

export const setLocalStorageVal = (
  key: LOCAL_STORAGE_KEYS,
  val: string | number | object
): void => {
  if (localStorage && val) {
    if (typeof val === 'object') {
      localStorage.setItem(key, JSON.stringify(val));
    } else {
      localStorage.setItem(key, `${val}`);
    }
  }
};

export const getLocalStorageVal = (key: LOCAL_STORAGE_KEYS): string | number | object => {
  if (localStorage) {
    const value = localStorage.getItem(key);
    try {
      if (value && typeof value === 'string') return JSON.parse(value);
    } catch (err) {
      return value ?? '';
    }
  }
  return '';
};
