import React from 'react';

import PrizeTable from '@components/PrizeTable';
import Typography from '@mui/material/Typography';
import { getCurrentMonthYear } from '@utils/dateHelpers';
import Consts from '@consts/index';

const MonthlyPrizeContainer = () => {
  const prizeTitle = Consts.Strings.prizeAndBrief.prizeTitle.replace(
    '<month-year>',
    getCurrentMonthYear()
  );
  const prizeNote = Consts.Strings.prizeAndBrief.prizeNote.replace(
    '<min_votes>',
    Consts.Configs.MINIMUM_VOTES.toString()
  );
  return (
    <>
      <Typography
        variant="h5"
        textAlign={'left'}
        marginLeft={0.5}
        textTransform={'capitalize'}
        fontWeight={700}
        gutterBottom>
        {prizeTitle}
      </Typography>
      <PrizeTable prizes={Consts.Prizes.baby} />
      <Typography
        gutterBottom
        textAlign={'left'}
        marginLeft={0.5}
        lineHeight={2.5}
        variant="caption">
        {prizeNote}
      </Typography>
    </>
  );
};

export default MonthlyPrizeContainer;
