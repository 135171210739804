import { Clubs, ShareItWith } from '../types';
import { getContestVotingEndDate, getOngoingContestMonth } from './dateHelpers';
import { getContestName } from './viewHelpers';

export const formatShareMessage = (
  shareWith: ShareItWith,
  name: string,
  votingUrl: string,
  contest: Clubs
) => {
  let message = '';
  const contestMonth = getOngoingContestMonth();
  const contestName = `${getContestName(contest)} Contest`;
  switch (shareWith) {
    case ShareItWith.whatsApp:
      message =
        `🙏 Hello, need your support to win *${contestName}* (${contestMonth.month} ${contestMonth.year}).%0a%0a` +
        `Please vote for *${name}* ` +
        'on every 30 miniutes till ' +
        `*${getContestVotingEndDate()}*. %0a%0a` +
        `*Please click below 👇 link to Vote* %0a` +
        votingUrl;

      break;
  }
  return message;
};
