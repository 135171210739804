import React from 'react';

import Button from '@mui/material/Button';
import BoxWrapper from './common/BoxWrapper';
import Consts from '@consts/index';
import { AppRoutes } from '@navigation/AppRoutes';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface IProps {
  iconfontSize?: 'large' | 'small' | 'medium' | 'inherit' | 'undefined';
  btnSize?: 'large' | 'small' | 'medium' | 'inherit' | 'undefined';
}

const ParticipateBtn = (props: IProps) => {
  const { iconfontSize = 'large', btnSize = 'large' } = props;
  const navigate = useNavigate();

  const openJoinPage = () => {
    navigate(AppRoutes.participate);
  };

  return (
    <BoxWrapper justifyContent="center">
      <Button
        variant="contained"
        onClick={openJoinPage}
        endIcon={
          // @ts-ignore
          <ArrowRightIcon fontSize={btnSize} />
        }
        // @ts-ignore
        size={iconfontSize}>
        {Consts.Strings.common.participate}
      </Button>
    </BoxWrapper>
  );
};

export default ParticipateBtn;
