import React from 'react';

import TitleAndBodySectionCard from '../TitleAndBodySectionCard';
import VotingConsts from '@consts/voting.json';
import AppConsts from '@consts/appStrings.json';

import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Button from '@mui/material/Button';
import { getOngoingContestMonth } from '@utils/dateHelpers';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@navigation/AppRoutes';

const ViewLbButton = ({ month, year }: { month: string; year: number }) => {
  const navigate = useNavigate();
  const openLb = () => {
    navigate(
      AppRoutes.leaderboard.replace(':month', month.toLowerCase()).replace(':year', `${year}`)
    );
  };

  return (
    <Button
      size={'medium'}
      sx={{
        width: '100%',
        marginTop: 1
      }}
      onClick={openLb}
      variant="contained">
      {AppConsts.actions.showLb}
    </Button>
  );
};
const ShowLBCard = () => {
  const { month, year } = getOngoingContestMonth();
  const bodyTxt = VotingConsts.viewLB.body
    .replace('<voting_month>', month)
    .replace('<year>', `${year}`);
  return (
    <TitleAndBodySectionCard
      body={bodyTxt}
      title={VotingConsts.viewLB.title}
      id="show-lb-card"
      Icon={LeaderboardIcon}
      footer={<ViewLbButton month={month} year={year} />}
    />
  );
};

export default ShowLBCard;
