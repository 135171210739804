export const AppRoutes = {
  home: '/',
  participate: '/participate',
  prizes: '/prizes',
  about: '/about',
  contactus: '/contactus',
  privacyPolicy: '/privacy-policy',
  rules: '/rules',
  results: '/results',
  faq: '/faq',
  copyRight: '/copyright',
  termsAndConditions: '/terms',
  howItWorks: '/how-to-play',
  vote: '/vote/:month/:year',
  leaderboard: '/leaderboard/:month/:year',
};
