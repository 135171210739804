import React from 'react';
import Box from '@mui/material/Box';
import RegistrationOpenContainer from '@containers/RegistrationOpenContainer';
import VotingMainContainer from '@containers/VotingMainContainer';
import HeadingWithBackGround from '@components/HeadingWithBackGround';
import { Clubs } from '../types/index';
import { useSearchParams } from 'react-router-dom';
import { getContestName } from '@utils/viewHelpers';
import { getOngoingContestMonth } from '@utils/dateHelpers';

const VotingPage = () => {
  const [urlSearchParams] = useSearchParams();
  const contestClub = (urlSearchParams.get('contest') as Clubs) ?? Clubs.cuteBaby;
  const onGoingMonth = getOngoingContestMonth();
  return (
    <>
      <Box>
        <HeadingWithBackGround
          headerBgColor={'#218a8a'}
          heading={`${getContestName(contestClub)} Contest (${onGoingMonth.month} ${
            onGoingMonth.year
          })`}
        />
      </Box>
      <Box mt={3}>
        <RegistrationOpenContainer />
      </Box>
      <VotingMainContainer />
      <Box mt={3}>
        <RegistrationOpenContainer />
      </Box>
    </>
  );
};

export default VotingPage;
